<template>
    <div>
        <page-header :title="title" :items="items" />

        <tabs
            :items="sections"
            :active-item="section"
            is-card
            class="mb-3"
            @item-change="section = $event"
        />

        <div class="col-12 p-0">
            <component :is="section.value" />
        </div>
    </div>
</template>

<script>
import Tabs from '@/components/elements/Tabs';
import Profile from '@/components/account/Profile';
import Password from '@/components/account/Password';

export default {
    page: {
        title: 'My account'
    },

    components: {
        Profile,
        Password,
        Tabs
    },

    data() {
        return {
            title: 'My Account',
            items: [
                {
                    text: 'Container Tracking',
                    to: '/'
                },
                {
                    text: 'My account',
                    active: true
                }
            ],
            sections: [
                {
                    value: 'profile',
                    text: 'Update your account'
                },
                {
                    value: 'password',
                    text: 'Change your password'
                }
            ],
            section: {
                value: 'profile'
            }
        };
    }
};
</script>
