<template>
    <div class="card">
        <div class="card-body">
            <form class="row" @submit.prevent="submitUserDataHandler">
                <div class="form-group col-12">
                    <label for="firstName">First Name</label>
                    <input
                        id="firstName"
                        v-model="user.firstName"
                        :class="{
                            'is-invalid': hasError('firstName')
                        }"
                        class="form-control"
                        type="text"
                    />
                    <div class="invalid-feedback">
                        {{ getError('firstName') }}
                    </div>
                </div>
                <div class="form-group col-12">
                    <label for="lastName">Last Name</label>
                    <input
                        id="lastName"
                        v-model="user.lastName"
                        :class="{
                            'is-invalid': hasError('lastName')
                        }"
                        class="form-control"
                        type="text"
                    />
                    <div class="invalid-feedback">
                        {{ getError('lastName') }}
                    </div>
                </div>
                <div class="form-group col-12">
                    <label for="email">Email</label>
                    <input
                        id="email"
                        v-model="user.email"
                        :class="{
                            'is-invalid': hasError('email')
                        }"
                        class="form-control"
                        type="text"
                        @input="isEmailTaken = false"
                    />
                    <div class="invalid-feedback">
                        {{ getError('email') }}
                    </div>
                </div>
                <div class="form-group col-12">
                    <label for="companyName">Company Name</label>
                    <input
                        id="companyName"
                        v-model="user.companyName"
                        class="form-control"
                        type="text"
                    />
                </div>
                <div class="col-12">
                    <button
                        type="submit"
                        class="btn btn-success waves-effect waves-light"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mapActions, mapGetters } from 'vuex';
import { StatusCodes as HTTP } from 'http-status-codes';
import { required, minLength, email } from 'vuelidate/lib/validators';

export default {
    validations: {
        user: {
            email: {
                required,
                email
            },
            firstName: {
                required,
                minLength: minLength(2)
            },
            lastName: {
                required,
                minLength: minLength(2)
            }
        }
    },

    data() {
        return {
            user: {},
            serverErrors: []
        };
    },

    computed: {
        ...mapGetters({
            loggedUser: 'auth/loggedUser'
        })
    },

    created() {
        this.user = {
            email: this.loggedUser.email,
            firstName: this.loggedUser.firstName,
            lastName: this.loggedUser.lastName,
            companyName: this.loggedUser.companyName,
            avatarId: this.loggedUser.avatarId
        };
    },

    methods: {
        ...mapActions({
            updateProfile: 'auth/updateProfile'
        }),

        hasError(key) {
            return (
                this.$v.user[key].$error ||
                this.serverErrors.find(error => error.param === key)
            );
        },

        getError(key) {
            return this.$getValidationErrorMessageHandler.handle(
                this.$v.user,
                this.serverErrors,
                key
            );
        },

        async submitUserDataHandler() {
            this.serverErrors = [];

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.updateProfile(this.user);

                this.$toaster('Account has been updated!');
            } catch (error) {
                console.error(error);

                Sentry.captureException(error);

                const { response } = error;

                if (response) {
                    const { data, status } = response;

                    if (status === HTTP.BAD_REQUEST && data.errors) {
                        this.serverErrors = data.errors;

                        this.$toasterError('Recheck your form.');

                        return;
                    }
                }
            }
        }
    }
};
</script>
