<template>
    <div class="card">
        <div class="card-body">
            <form class="row" @submit.prevent="submitChangeMyPassword">
                <div class="form-group col-12">
                    <label for="currentPassword">Current Password</label>
                    <input
                        id="currentPassword"
                        v-model="passwords.currentPassword"
                        :class="{
                            'is-invalid':
                                $v.passwords.currentPassword.$error ||
                                wrongCurrentPassword
                        }"
                        class="form-control"
                        type="password"
                        @input="wrongCurrentPassword = false"
                    />
                    <div v-if="wrongCurrentPassword" class="invalid-feedback">
                        Wrong current password
                    </div>
                </div>
                <div class="form-group col-12">
                    <label for="password">New password</label>
                    <input
                        id="password"
                        v-model="passwords.password"
                        :class="{
                            'is-invalid': $v.passwords.password.$error
                        }"
                        class="form-control"
                        type="password"
                    />
                    <div v-if="passwordErrors.length" class="invalid-feedback">
                        {{ passwordErrors[0] }}
                    </div>
                </div>
                <div class="form-group col-12">
                    <label for="passwordConfirmation">
                        Password confirmation
                    </label>
                    <input
                        id="passwordConfirmation"
                        v-model="passwords.passwordConfirmation"
                        :class="{
                            'is-invalid':
                                $v.passwords.passwordConfirmation.$error
                        }"
                        class="form-control"
                        type="password"
                    />
                    <div
                        v-if="passwordConfirmationErrors.length"
                        class="invalid-feedback"
                    >
                        {{ passwordConfirmationErrors[0] }}
                    </div>
                </div>
                <div class="col-12">
                    <button
                        type="submit"
                        class="btn btn-success waves-effect waves-light"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

export default {
    validations: {
        passwords: {
            currentPassword: { required },
            password: {
                required,
                minLength: minLength(6),
                containDigit: v => {
                    return /\d/.test(v);
                }
            },
            passwordConfirmation: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },

    data() {
        return {
            passwords: {},
            wrongCurrentPassword: false
        };
    },

    computed: {
        passwordErrors() {
            const errors = [];

            if (!this.$v.passwords.password.$dirty) return errors;
            !this.$v.passwords.password.required &&
                errors.push('New Password is required.');
            !this.$v.passwords.password.minLength &&
                errors.push(
                    'New Password cannot contain less than 6 characters!'
                );
            !this.$v.passwords.password.containDigit &&
                errors.push('New Password must contain numbers and letters!');

            return errors;
        },

        passwordConfirmationErrors() {
            const errors = [];

            if (!this.$v.passwords.passwordConfirmation.$dirty) return errors;

            !this.$v.passwords.passwordConfirmation.required &&
                errors.push('Password Confirmation is required');
            !this.$v.passwords.passwordConfirmation.sameAsPassword &&
                errors.push(
                    'Password confirmation must be equal to New Password'
                );

            return errors;
        }
    },

    methods: {
        ...mapActions({
            updatePassword: 'auth/updatePassword'
        }),

        async submitChangeMyPassword() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.updatePassword(this.passwords);

                this.passwords = {};
                this.$v.$reset();

                this.$toaster('Password has been changed!');
            } catch (err) {
                console.error(err);

                Sentry.captureException(err);

                if (
                    err.response &&
                    err.response.status === 400 &&
                    err.response.data &&
                    err.response.data.errors &&
                    err.response.data.errors.find(
                        e => e.param === 'currentPassword'
                    )
                ) {
                    this.wrongCurrentPassword = true;
                } else {
                    this.$toasterError();
                }
            }
        }
    }
};
</script>
